import Head from 'next/head'
import { useRouter } from 'next/router'
import {
  PagesMetaContent,
  MakeOptional,
} from 'types/generated/contentful-types'

export type MetaContentProps = MakeOptional<
  PagesMetaContent,
  'sys' | 'contentfulMetadata' | '_id'
> & {
  noindexNofollow?: boolean
  url?: string
  children?: React.ReactNode
  ogType?: string
  jsonLd?: any
}

export const MetaContentFragment = `
  metaContent {
    metaTitle
    metaDescription
    metaImage {
      url
    }
  }
`

export function MetaContentHead({
  metaTitle,
  metaDescription,
  metaImage,
  noindexNofollow,
  url,
  children,
  ogType,
  jsonLd,
}: MetaContentProps): JSX.Element {
  const router = useRouter()
  const imageUrl = metaImage?.url
    ? metaImage.url
    : 'https://media.foratravel.com/image/upload/v1723225677/ForaTravel_OG_is6exr.jpg'

  return (
    <Head>
      <title>{metaTitle || ''}</title>
      <meta
        httpEquiv="Content-Security-Policy"
        content="upgrade-insecure-requests"
      />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0,  maximum-scale=1"
      />
      <meta name="description" content={metaDescription || ''} />
      <meta
        property="og:url"
        content={`https://www.foratravel.com${router?.asPath || url || '/'}`}
      />
      <link
        rel="canonical"
        href={`https://www.foratravel.com${router?.asPath || url || '/'}`}
      />
      <meta
        property="og:title"
        content={metaTitle || 'Fora: The Modern Travel Agency'}
      />
      <meta
        property="og:description"
        content={
          metaDescription ||
          "Fora's expert travel advisors will help you plan your dream trip while unlocking exclusive perks and upgrades. Welcome to the modern travel agency."
        }
      />
      <meta property="og:image" content={imageUrl} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      {noindexNofollow && <meta name="robots" content="noindex,nofollow" />}
      {/* Google/Search Engine tags */}
      <meta
        itemProp="name"
        content={metaTitle || 'Fora: The Modern Travel Agency'}
      />
      <meta itemProp="description" content={metaDescription || ''} />
      <meta itemProp="image" content={imageUrl} />
      {/* Twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={metaTitle || 'Fora: The Modern Travel Agency'}
      />
      <meta
        name="twitter:description"
        content={
          metaDescription ||
          "Fora's expert travel advisors will help you plan your dream trip while unlocking exclusive perks and upgrades. Welcome to the modern travel agency."
        }
      />
      <meta name="twitter:image" content={imageUrl} />
      {/* Pinterest Rich pins tags */}
      <meta name="og:type" content={ogType || 'website'} />
      <meta name="og:site_name" content="Fora Travel" />
      {jsonLd && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
        />
      )}
      {children}
    </Head>
  )
}
